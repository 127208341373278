import React from "react";
import { Helmet } from "react-helmet";
const renderGoogleAnalytics = () => {
  const scripts = [];
  scripts.push(
    <script
      key={1}
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-1GBZ1SZJJJ"
    ></script>
  );
  scripts.push(
    <script key={2}>
      {` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-1GBZ1SZJJJ');`}
    </script>
  );
  scripts.push(
    <noscript key="image" id="facebook-pixel-image">
      {`<img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=299142589449037&ev=PageView&noscript=1"
      />`}
    </noscript>
  );

  return scripts;
};
const GoogleAnalytics = () => {
  return <Helmet>{renderGoogleAnalytics()}</Helmet>;
};

export default GoogleAnalytics;
